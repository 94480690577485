export const PERMISSIONS = {
    REPORT_SUBSCRIPTION_CREATE: 'report_subscription_create',
    USER_MANAGER_LIST: 'user_manager_list',
    USER_MANAGER_CREATE: 'user_manager_create',
    USER_MANAGER_DELETE: 'user_manager_delete',
    USER_MANAGER_EDIT: 'user_manager_edit',

    SETTING_LIST: 'setting_list',
    SETTING_EDIT: 'setting_edit',

    MARKETPLACE_LIST: 'marketplace_list',
    WL_MARKETPLACE_EDIT: 'marketplace_edit',

    BILLING_LIST: 'billing_list',
    BILLING_CREATE: 'billing_create',
    BILLING_SHOW: 'billing_show',
    BILLING_EDIT: 'billing_edit',
    BILLING_DELETE: 'billing_delete',

    WL_LIST: 'wl_list',

    WL_SERVICE_LIST: 'wl_service_list',
    WL_SERVICE_CREATE: 'wl_service_create',
    WL_SERVICE_EDIT: 'wl_service_edit',
    WL_SERVICE_DELETE: 'wl_service_delete',
    WL_SERVICE_SHOW: 'wl_service_show',

    WL_PARTNER_SHOW: 'wl_partner_show',
    WL_PARTNER_LIST: 'wl_partner_list',
    WL_PARTNER_CREATE: 'wl_partner_create',
    WL_PARTNER_EDIT: 'wl_partner_edit',
    WL_PARTNER_DELETE: 'wl_partner_delete',

    WL_ORDER_CREATE: 'wl_order_create',
    WL_ORDER_SHOW: 'wl_order_show',
    WL_ORDER_LIST: 'wl_order_list',
    WL_ORDER_DELETE: 'wl_order_delete',

    WL_QUOTE_CREATE: 'wl_quote_create',
    WL_QUOTE_EDIT: 'wl_quote_edit',
    WL_QUOTE_DELETE: 'wl_quote_delete',
    WL_QUOTE_SHOW: 'wl_quote_show',
    WL_QUOTE_LIST: 'wl_quote_list',

    WL_INVOICE_CREATE: 'wl_invoice_create',
    WL_INVOICE_EDIT: 'wl_invoice_edit',
    WL_INVOICE_LIST: 'wl_invoice_list',
    WL_INVOICE_SHOW: 'wl_invoice_show',
    WL_INVOICE_DELETE: 'wl_invoice_delete',

    WL_RECURRING_INVOICE_EDIT: 'wl_recurringInvoice_edit',
    WL_RECURRING_INVOICE_SHOW: 'wl_recurringInvoice_show',
    WL_RECURRING_INVOICE_LIST: 'wl_recurringInvoice_list',

    WL_HELPDESK_LIST: 'wl_helpdesk_list',
    WL_HELPDESK_EDIT: 'wl_helpdesk_edit',
    WL_HELPDESK_CREATE: 'wl_helpdesk_create',
    WL_HELPDESK_SHOW: 'wl_helpdesk_show',

    WL_REVENUE_LIST: 'wl_revenue_list',
    WL_REVENUE_CREATE: 'wl_revenue_create',

    WL_PAYOUTS_EDIT: 'wl_payouts_edit',
    WL_PAYOUTS_CREATE: 'wl_payouts_create',
    WL_PAYOUTS_LIST: 'wl_payouts_list',

    POS_PAYOUTS_CREATE: 'pos_payouts_create',
    POS_PAYOUTS_EDIT: 'pos_payouts_edit',
    POS_PAYOUTS_LIST: 'pos_payouts_list',

    POS_LIST: 'pos_list',
    POS_MANAGEMENT: 'pos_management',
    POS_MANAGEMENT_LIST: 'pos_management_list',
    POS_CATALOG: 'pos_catalog',
    POS_CATALOG_LIST: 'pos_catalog_list',

    WL_SETTING_CREATE: 'wl_setting_create',

    WL_CONTACT_SHOW: 'wl_contact_show',
    WL_CONTACT_CREATE: 'wl_contact_create',
    WL_CONTACT_DELETE: 'wl_contact_delete',
    WL_CONTACT_EDIT: 'wl_contact_edit',

    WL_RESOURCE_LIST: 'wl_resource_list',
    WL_RESOURCE_CREATE: 'wl_resource_create',
    WL_RESOURCE_DELETE: 'wl_resource_delete',
    WL_RESOURCE_EDIT: 'wl_resource_edit',
    WL_SETTING_LIST: 'wl_setting_list',

    WL_PAYMENTSETTING_SHOW: 'pos_paymentSetting_show',
    WL_PAYMENTSETTING_CREATE: 'pos_paymentSetting_create',
    WL_PAYMENTSETTING_EDIT: 'pos_paymentSetting_edit',
    WL_PAYMENTSETTING_DELETE: 'pos_paymentSetting_delete',
};

export const ACTIONS_ORDER = ['list', 'show', 'create', 'edit', 'delete'];
